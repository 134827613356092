import * as React from 'react'
import { Button, Checkbox, Pane, Table, TextInput } from 'evergreen-ui'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

const LogoutButton: React.FC = () => {
  const [loggedIn, setLoggedIn] = React.useState(true)

  const handleLogout = event => {
    axios
      .delete('/logout', { withCredentials: true })
      .then(function (response) {
        console.log(response)
        console.log('Logging off')
        setLoggedIn(false)
      })
      .catch(function (error) {
        console.log(error)
        setLoggedIn(false)
      })
  }

  return loggedIn ? <Button onClick={handleLogout}>Log Out</Button> : <Redirect to="/" />
}

export default LogoutButton
