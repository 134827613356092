import * as React from 'react'
import { Button, Pane, Text, FormField, TextInput, Icon, Select } from 'evergreen-ui'
import axios from 'axios'
import startOfToday from 'date-fns/startOfToday'

// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";

import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

interface propsInterface {
  // onNewStudent: () => void
  userId: string
}

const styles = {
  formPadding: { padding: 10 }
}

const CreatePaymentReminder: React.FC<propsInterface> = props => {
  const [success, setSuccess] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [name, setName] = React.useState('')
  const [amount, setAmount] = React.useState('')
  const [dueDate, setDueDate] = React.useState(startOfToday)

  const [selectedPayeeId, setSelectedPayeeId] = React.useState('')

  // consider moving this to homepage but should be fine here for now
  const [payees, setPayees] = React.useState([])

  React.useEffect(() => {
    // async await?
    axios
      .get('api/payees/', { withCredentials: true })
      .then(function (response) {
        const { data } = response
        if (data.payees) {
          setPayees(data.payees)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const handleSubmit = event => {
    event.preventDefault()

    setLoading(true)

    const data = {
      paymentReminder: {
        userId: props.userId,
        payeeId: selectedPayeeId,
        name: name,
        amount: amount,
        dueOn: dueDate
      }
    }

    axios
      .post('/api/payment_reminders', data)
      .then(function (response) {
        setSuccess(true)
      })
      .catch(function (error) {
        console.log(error)
      })
      .then(function () {
        // always executed
      })

    setLoading(false)
    // props.onNewStudent()
  }

  const onPayeeChange = e => {
    setSelectedPayeeId(e.target.value)
  }

  return (
    <>
      <form onSubmit={handleSubmit} style={styles.formPadding}>
        <FormField label="Reminder Title">
          <TextInput
            name="Title"
            placeholder="Rogers Mastercard"
            onChange={e => setName(e.target.value)}
            value={name}
          />
        </FormField>
        OR
        <FormField label="Payee">
          <Select onChange={event => onPayeeChange(event)}>
            <option value={0} selected>
              Pick a Payee
            </option>
            {payees.map(payee => {
              if (payee.id == selectedPayeeId) {
                return (
                  <option value={payee.id} selected>
                    {payee.nickname}
                  </option>
                )
              }
              return <option value={payee.id}>{payee.nickname}</option>
            })}
          </Select>
        </FormField>
        <FormField label="Amount Due" isRequired>
          <TextInput name="amount" placeholder="204.92" onChange={e => setAmount(e.target.value)} value={amount} />
        </FormField>
        <FormField label="Due Date" isRequired>
          <DatePicker
            selected={dueDate}
            onChange={date => {
              setDueDate(date)
            }}
          />
        </FormField>
        <Button>Save</Button>
        {success && <Icon icon="tick-circle" color="success" marginRight={16} />}
        {!success && <Icon icon="ban-circle" color="danger" marginRight={16} />}
      </form>
    </>
  )
}

export default CreatePaymentReminder
