import * as React from 'react'
import { Button, Checkbox, Pane, Table, TextInput, IntentTypes } from 'evergreen-ui'
import axios from 'axios'

interface propsInterface {
  userId: string
  // newStudentCount: number
  // updateStudentCount: (number) => void
  onReminderSelect: (string) => void
  // onContactPointSave: (number) => void
}

const PaymentReminderList: React.FC<propsInterface> = props => {
  const [paymentReminders, setPaymentReminders] = React.useState([])
  // const [studentCount, setStudentCount] = React.useState(0)

  // consider moving this do the homepage component and passing array of students
  React.useEffect(() => {
    axios
      .get('api/payment_reminders', { withCredentials: true })
      .then(function (response) {
        const { data } = response

        if (data.paymentReminders) {
          setPaymentReminders(data.paymentReminders)
        }
        // setStudentCount(response.data.length)
        // props.updateStudentCount(response.data.length)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const markAsPaid = id => {
    console.log(id)
    axios
      .put(`api/payment_reminders/${id}/mark_as_paid`, { withCredentials: true })
      .then(function (response) {
        const { data } = response

        // debugger
        if (data.paymentReminders) {
          setPaymentReminders(data.paymentReminders)
        }

        // setStudentCount(response.data.length)
        // props.updateStudentCount(response.data.length)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell flexBasis={200} flexShrink={0} flexGrow={0}>
            Name / Payee
          </Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={250} flexShrink={0} flexGrow={0}>
            Amount
          </Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
            Due Date
          </Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {paymentReminders.map(paymentReminder => {
            var rowIntent: IntentTypes = 'none'
            if (new Date().toISOString().slice(0, 10) > paymentReminder.due_on) {
              rowIntent = 'warning'
            } else if (paymentReminder.paid_at) {
              rowIntent = 'success'
            }

            return (
              <Table.Row
                key={paymentReminder.id}
                intent={rowIntent}
                isSelectable
                onSelect={() => props.onReminderSelect(paymentReminder.payee.id)}
              >
                <Table.TextCell>
                  {paymentReminder.name || paymentReminder.payee.nickname} / {paymentReminder.payee_id}
                </Table.TextCell>
                <Table.TextCell>{paymentReminder.amount / 100}</Table.TextCell>
                <Table.TextCell>{paymentReminder.due_on}</Table.TextCell>
                <Table.TextCell>
                  {paymentReminder.paid_at == null && (
                    <Button appearance="primary" intent="success" onClick={() => markAsPaid(paymentReminder.id)}>
                      Mark as Paid
                    </Button>
                  )}
                  {paymentReminder.paid_at && <p>Paid </p>}
                </Table.TextCell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
}

export default PaymentReminderList
