// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Button, Pane } from 'evergreen-ui'
import App from '../App'

import { BrowserRouter, Route, Switch } from 'react-router-dom'

// import PaymentList from '../components/organisms/PaymentList'
// import PaymentReminderInput from '../components/organisms/PaymentReminderInput'

// interface propInterface {
//   name: string
// }

// const Welcome: React.SFC<propInterface> = props => {
//   return (
//     <Pane clearfix>
//       <Pane
//         elevation={0}
//         float="left"
//         backgroundColor="white"
//         width={'25%'}
//         // height={120}
//         margin={24}
//         padding={20}
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         // flexDirection="column"
//       >
//         <PaymentReminderInput />
//       </Pane>
//       <Pane
//         elevation={1}
//         float="left"
//         width={'50%'}
//         // height={120}
//         margin={24}
//         // display="flex"
//         // justifyContent="center"
//         // alignItems="center"
//         // flexDirection="column"
//       >
//         <PaymentList />
//       </Pane>
//     </Pane>
//   )
// }

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.body.appendChild(document.createElement('div'))
  )
})
