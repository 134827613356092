import * as React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

import { Button, Pane } from 'evergreen-ui'
// import App from '../App'
import PaymentReminderList from '../organisms/PaymentReminderList'
import PayeeDetails from '../organisms/PayeeDetails'
import CreatePaymentReminder from '../organisms/CreatePaymentReminder'
import LoginInput from '../organisms/LoginInput'
import LogoutButton from '../molecules/LogoutButton'

interface propsInterface {
  userId: string
}

const Homepage: React.SFC<propsInterface> = props => {
  const [studentCount, setStudentCount] = React.useState(0)
  const [randomNumber, setRandomNumber] = React.useState(0)
  const [selectedPayeeId, setSelectedPayeeId] = React.useState('0')

  return (
    <>
      <div>
        <Button appearance="primary" intent="success">
          Turn off the lights!
        </Button>
      </div>
      <Pane clearfix width={'100%'}>
        <Pane
          clearfix
          float="left"
          backgroundColor="white"
          width={'35%'}
          display="block"
          justifyContent="center"
          alignItems="center"
        >
          <Pane elevation={1}>
            <CreatePaymentReminder
              userId={props.userId}
              // onNewStudent={() => {
              //   setStudentCount(studentCount + 1)
              // }}
            />
          </Pane>

          <Pane elevation={1} marginTop={20}>
            <h2>Today: {new Date().toISOString().slice(0, 10)}</h2>
            <h2>Selected remidner Id {selectedPayeeId}</h2>
            <PayeeDetails userId={props.userId} selectedPayeeId={selectedPayeeId} randomNumber={randomNumber} />

            <Button onClick={() => setSelectedPayeeId('0')}>All Payees Data</Button>
          </Pane>
        </Pane>
        <Pane elevation={1} float="left" width={'65%'}>
          <PaymentReminderList
            // newStudentCount={studentCount}
            userId={props.userId}
            // updateStudentCount={setStudentCount}
            onReminderSelect={setSelectedPayeeId}
            // onContactPointSave={setRandomNumber}
          />
        </Pane>
      </Pane>
      <LogoutButton />
    </>
  )
}

export default Homepage
