import * as React from 'react'
import { Button, Checkbox, Pane, Table, Heading, Icon } from 'evergreen-ui'
import { format } from 'date-fns'
import axios from 'axios'
import { ResponsiveBar } from '@nivo/bar'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import { formatISO } from 'date-fns'

// import StudentRow from '../molecules/StudentRow'

interface propsInterface {
  userId: string
  selectedPayeeId: string
  randomNumber: number
}

const data = [
  {
    name: 'Page A',
    uv: 4000
  },
  {
    name: 'Page B',
    uv: 3000
  },
  {
    name: 'Page C',
    uv: 2000
  },
  {
    name: 'Page D',
    uv: 2780
  },
  {
    name: 'Page E',
    uv: 1890
  },
  {
    name: 'Page F',
    uv: 2390
  },
  {
    name: 'Page G',
    uv: 3490
  }
]

const PayeeDetails: React.FC<propsInterface> = props => {
  // const [selectedPayeeId, setselectedPayeeId] = React.useState(props.selectedPayeeId)
  const [studentName, setStudentName] = React.useState('')
  const [reminders, setReminders] = React.useState([])
  const [paymentChartData, setPaymentChartData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [success, setSuccess] = React.useState(false)
  const [editMode, setSditMode] = React.useState(false)

  React.useEffect(() => {
    // we need to take the payment reminder and fine payee

    axios
      .get(`api/payees/${props.selectedPayeeId}`, { withCredentials: true })
      .then(function (response) {
        setReminders(response.data.payments)
        setPaymentChartData(response.data.chart_data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [props.selectedPayeeId])

  const formatedDate = date => {
    if (date == undefined) {
      return ''
    }
    const jsDate = new Date(date)
    return formatISO(jsDate, { representation: 'date' }) // '2019-01-02' string
  }

  return (
    <>
      <Heading is="h1">Payment History</Heading>

      <LineChart
        width={400}
        height={200}
        data={paymentChartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="amount" stroke="#8884d8" activeDot={{ r: 8 }} />
        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
      </LineChart>

      <Table>
        <Table.Head>
          <Table.TextHeaderCell>Amount</Table.TextHeaderCell>
          <Table.TextHeaderCell>Status</Table.TextHeaderCell>
          <Table.TextHeaderCell>Due Date</Table.TextHeaderCell>
          <Table.TextHeaderCell>Paid On</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {reminders.map((object, i) => {
            return (
              <Table.Row key={object.id}>
                <Table.TextCell>${object.amount / 100}</Table.TextCell>
                <Table.TextCell>{object.paid_at == null ? 'Not Paid' : 'Paid'}</Table.TextCell>
                <Table.TextCell>{object.due_on}</Table.TextCell>

                <Table.TextCell>{formatedDate(object.paid_at)}</Table.TextCell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {/* <Button appearance="primary" intent="success" onClick={handleSave}> */}
      {success && <Icon icon="tick-circle" color="danger" marginRight={16} />}
    </>
  )
}

export default PayeeDetails
