import * as React from 'react'
import { BrowserRouter, Switch, Route, Link, Redirect } from 'react-router-dom'
import axios from 'axios'

import AuthenticatedRoute from './AuthenticatedRoute'

import LoginPage from './components/views/Login'
import HomePage from './components/views/Homepage'

const App: React.FC = () => {
  return (
    <Switch>
      <AuthenticatedRoute path="/home" component={HomePage} />
      <Route path="/" component={LoginPage} exact />
    </Switch>
  )
}

export default App
