import * as React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

import { Button, Pane } from 'evergreen-ui'
// import App from '../App'
// import PaymentList from './components/organisms/PaymentList'
// import PaymentReminderInput from './components/organisms/PaymentReminderInput'

import LoginInput from '../organisms/LoginInput'

const Login: React.SFC = props => {
  return (
    <Pane clearfix>
      <Pane
        elevation={0}
        float="left"
        backgroundColor="white"
        width={'25%'}
        // height={120}
        margin={24}
        padding={20}
        display="flex"
        justifyContent="center"
        alignItems="center"
        // flexDirection="column"
      >
        <LoginInput />
      </Pane>
    </Pane>
  )
}

export default Login
