import * as React from 'react'
import { Redirect, Route } from 'react-router-dom'
import axios from 'axios'

interface propsInterface {
  path: string
  component: any
}

const AuthenticatedRoute: React.FC<propsInterface> = ({ component: Component, ...rest }) => {
  const [userId, setUserId] = React.useState('')
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    setLoading(true)
    axios
      .get('/logged_in', { withCredentials: true })
      .then(function (response) {
        if (response.status == 200) {
          setUserId(response.data.id)
        } else {
          setUserId('')
        }
      })
      .catch(() => {
        setUserId('')
      })
      .then(() => {
        setLoading(false)
      })
  }, [])

  return (
    <>
      {loading == false && (
        <Route
          {...rest}
          render={props =>
            userId == '' ? <Redirect to={{ pathname: '/' }} /> : <Component userId={userId} {...props} />
          }
        />
      )}
    </>
  )
}

export default AuthenticatedRoute
