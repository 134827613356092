import * as React from 'react'
import { Button, FormField, TextInput, Icon, Select, Combobox } from 'evergreen-ui'

import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'

const LoginInput: React.FC = () => {
  const [loggedIn, setLoggedIn] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const handleSubmit = event => {
    event.preventDefault()

    const data = {
      auth: {
        email: email,
        password: password
      }
    }

    axios
      .post('/login', data)
      .then(function (response) {
        console.log(response)
        setLoggedIn(true)
      })
      .catch(function (error) {
        console.log(error)
      })
      .then(function () {
        // always executed
      })
  }

  if (loggedIn) {
    return <Redirect to="/home" />
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormField label="email" isRequired>
          <TextInput name="email" onChange={e => setEmail(e.target.value)} value={email} />
        </FormField>
        <FormField label="Password" isRequired>
          <TextInput name="password" type="password" onChange={e => setPassword(e.target.value)} value={password} />
        </FormField>

        <FormField>
          <Button>Login</Button>

          {loggedIn && <Icon icon="tick-circle" color="success" marginRight={16} />}
          {!loggedIn && <Icon icon="ban-circle" color="danger" marginRight={16} />}
        </FormField>
      </form>
    </>
  )
}

export default LoginInput
